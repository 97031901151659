<template>
  <gf-base>
    <gf-card title="Shipments" subtitle="List of Shipments">
      <template #header>
        <el-input style="width: 250px" class="mr-4" size="medium" prefix-icon="el-icon-search" placeholder="Search by name or company code ..." v-model="search"></el-input>
      </template>
      <gf-table :data="shipments" :column-filter="columnFilter" @row-click="readShipment" v-loading="loading">
        <el-table-column v-if="columnFilter[0].show" label="Order #" min-width="120" prop="order.so_number">
        </el-table-column>
        <el-table-column v-if="columnFilter[1].show" label="Status" min-width="120">
          <template slot-scope="slot">
            <gf-label inline pill type="info" v-if="slot.row.is_fulfilled">Fulfilled</gf-label>
            <gf-label inline pill type="warning" v-else>Packed</gf-label>
          </template>
        </el-table-column>
        <el-table-column v-if="columnFilter[2].show" label="Company Name" min-width="200" prop="customer.name">
        </el-table-column>
        <el-table-column v-if="columnFilter[4].show" label="Last Updated" min-width="120">
          <template slot-scope="slot">
            {{ $DateFormat(slot.row.updated_at) }}
          </template>
        </el-table-column>
      </gf-table>
      <template #footer>
        <gf-pagination :pagination="pagination" @change="getShipments"></gf-pagination>
      </template>
    </gf-card>
  </gf-base>
</template>

<style lang="scss" scoped>
.circle {
  width: 18px;
  height: 18px;
  border: 2px solid #585d64;
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;

  &--half {
    background: linear-gradient(90deg, #585d64 50%, #fff 50%);
  }

  &--full {
    background: #585d64;
  }
}
</style>

<script>
// Services
import ShipmentService from '@/services/v1/Shipment'

// Lodash
import { debounce } from 'lodash'

export default {
  data () {
    return {
      loading: false,
      columnFilter: [
        { label: 'Order #', show: true },
        { label: 'Status', show: true },
        { label: 'Company Name', show: true },
        { label: 'Stock Location', show: true },
        { label: 'Last Updated', show: true }
      ],
      search: '',
      filter: {},

      shipments: [],

      // pagination
      pagination: {
        page: {
          size: 25,
          number: 1
        },
        total: 0
      }
    }
  },
  watch: {
    search: debounce(function (value) {
      if (value === '') {
        delete this.filter.q
      } else {
        this.filter.q = {
          like: value
        }
      }

      // Reset to page 1
      this.pagination.page.number = 1

      this.getShipments()
    }, 400)
  },
  methods: {
    readShipment (row) {
      this.$router.push({ name: 'shipment-view', params: { id: row.order.id, sid: row.id } })
    },

    // service fetch
    async getShipments () {
      try {
        this.loading = true
        const smService = new ShipmentService()
        const result = await smService.list(this.page, null, this.filter, null)
        this.shipments = result.data.rows

        if (result.data.count === 0 && result.data.rows.length !== 0) {
        } else this.pagination.total = result.data.count
      } catch (error) {
        this.$Error(error)
      } finally {
        this.loading = false
      }
    }
  },
  mounted () {
    this.$store.dispatch(this.$SET_BREADCRUMB, [
      { title: 'Sales Order', route: '' },
      { title: 'Invoices' }
    ])

    this.getShipments()
  }
}
</script>
